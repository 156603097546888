import { render, staticRenderFns } from "./TabellaFattureEmesse.vue?vue&type=template&id=b019f66c&"
import script from "./TabellaFattureEmesse.vue?vue&type=script&lang=js&"
export * from "./TabellaFattureEmesse.vue?vue&type=script&lang=js&"
import style0 from "./TabellaFattureEmesse.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QBtn,QSpinnerHourglass,QTable,QTr,QTh,QTd});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b019f66c')) {
      api.createRecord('b019f66c', component.options)
    } else {
      api.reload('b019f66c', component.options)
    }
    module.hot.accept("./TabellaFattureEmesse.vue?vue&type=template&id=b019f66c&", function () {
      api.rerender('b019f66c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Fatture/TabellaFattureEmesse.vue"
export default component.exports